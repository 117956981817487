<template>
  <div class="modal fade" id="glosarioModal" tabindex="-1" role="dialog" aria-labelledby="glosarioModal"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("title") }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul>
            <li>
              <div class="label-content">
                <a class="label-CANCELED" href="">{{ $t("canceled") }}</a>
              </div>
              <span>{{ $t("canceled_text") }}</span>
            </li>
            <li>
              <div class="label-content">
                <a class="label-PENDING" href="">{{ $t("pending") }}</a>
              </div>
              <span>{{ $t("pending_text") }}</span>
            </li>
            <li>
              <div class="label-content">
                <a class="label-PROCESSING" href="">{{ $t("processing") }}</a>
              </div>
              <span>{{ $t("processing_text") }}</span>
            </li>
            <li>
              <div class="label-content">
                <a class="label-PROCESSED" href="">{{ $t("processed") }}</a>
              </div>
              <span>{{ $t("processed_text") }}</span>
            </li>
            <li>
              <div class="label-content">
                <a class="label-APPROVED" href="">{{ $t("approved") }}</a>
              </div>
              <span>{{ $t("approved_text") }}</span>
            </li>
            <li>
              <div class="label-content">
                <a class="label-DELIVERED" href="">{{ $t("delivered") }}</a>
              </div>
              <span>{{ $t("delivered_text") }}</span>
            </li>
            <li>
              <div class="label-content">
                <a class="label-INVOICED" href="">{{ $t("invoiced") }}</a>
              </div>
              <span>{{ $t("invoiced_text") }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GlosarioModal",
  i18n: {
    messages: {
      en: {
        title: "Label Glossary",
        delivered: "Delivered",
        delivered_text: "The order has been delivered.",
        approved: "Approved",
        approved_text: "The order has been approved by Credits.",
        processed: "Pending Approval",
        processed_text: "The order is being validated in SAP McCain.",
        processing: "Sending",
        processing_text: "The order is being sent from the Portal to McCain.",
        invoiced: "Invoiced",
        invoiced_text: "The order has been invoiced.",
        pending: "Pending",
        pending_text: "Awaiting approval.",
        canceled: "Canceled",
        canceled_text: "Canceled by the seller.",
      },
      es: {
        title: "Glosario de etiquetas",
        delivered: "Entregado",
        delivered_text: "El pedido se ha entregado.",
        approved: "Aprobado",
        approved_text: "El pedido se ha aprobado por Créditos.",
        processed: "Pendiente de Aprobación",
        processed_text: "El pedido está siendo validado en SAP McCain.",
        processing: "Enviando",
        processing_text: "El pedido se está enviándo desde el Portal a McCain.",
        invoiced: "Facturado",
        invoiced_text: "El pedido ha sido facturado.",
        pending: "Pendiente",
        pending_text: "Esperando aprobación.",
        canceled: "Cancelada",
        canceled_text: "Cancelada por el vendedor.",
      }
    }
  },
  data() {
    return {}
  },
}
</script>