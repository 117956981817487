<template>
  <main-banner :banners="banners"></main-banner>
  <section class="pedidos-section">
    <div class="container">
      <div class="section-title">
        <h4>{{ $t("title") }}</h4>
        <label v-if="this.sold_tos_true">{{ soldTo }}</label>
        <a v-if="this.sold_tos_true" @click="volverListado()" href="javascript:void(0)">
          <img class="d-none d-sm-block" src="assets/img/backspace.svg" alt="backspace">
        </a>
      </div>
      <div class="row">
        <div class="col-md-4 index-option">
          <div class="box-pedido">
            <router-link :to="getRoute('list', route)">
              <img src="assets/img/pedidos1.png" alt="pedidos1">
              <h3>{{ $t("my_orders") }}</h3>
            </router-link>
          </div>
        </div>
        <div class="col-md-4 index-option">
          <div class="box-pedido">
            <router-link :to="getRoute('create', route)">
              <img src="assets/img/pedidos1.png" alt="icon">
              <h3>{{ $t("new_order") }}</h3>
            </router-link>
          </div>
        </div>
        <div class="col-md-4 index-option">
          <div class="box-pedido">
            <router-link :to="route + 'contacto'">
              <img src="assets/img/pedidos1.png">
              <h3>{{ $t("contact_us") }}</h3>
            </router-link>
          </div>
        </div>
        <div class="col-md-4 index-option" v-if="showSolicitudDeCambios">
          <div class="box-pedido">
            <router-link :to="route + 'change-order-list'">
              <img src="assets/img/pedidos1.png">
              <h3>{{ $t("request_changes") }}</h3>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {route} from "@/enviroment"
import MainBanner from "../layouts/MainBanner.vue"
// import axios from 'axios'
import axiosInstance from '../../main';
import authMixin from '@/mixins/authMixin';

export default {
  name: "PedidosMenu",
  i18n: {
    messages: {
      en: {
        title: "Online Orders",
        my_orders: "My Orders",
        new_order: "New Order",
        contact_us: "Contact Us",
        request_changes: "Request Changes"
      },
      es: {
        title: "Pedidos Online",
        my_orders: "Mis Pedidos",
        new_order: "Nuevo Pedido",
        contact_us: "Contáctenos",
        request_changes: "Solicitud de Cambios"
      }
    }
  },
  components: {
    MainBanner
  },
  data() {
    return {
      route: route,
      banners: [],
      sold_tos_true: false
    }
  },
  mixins: [authMixin],
  computed: {
    showSolicitudDeCambios() {
      return (sessionStorage.getItem('country') === 'Calatin');
    },
    soldTo() {
      return JSON.parse(sessionStorage.getItem('pwa-woe-sold-to')) || '';
    }
  },
  async created() {
    const valid = await this.validateUserAndSoldTo();
    if (valid) {
      await this.updateSoldTosTrue();
      await this.getUser();
      this.getBanners();
    }
  },
  methods: {
    isWebOrderImportActive() {
      return sessionStorage.getItem('pwa-woe-web-order-importador') === 'true' || false;
    },
    getRoute(action, baseRoute) {
      const isImporterActive = this.isWebOrderImportActive();
      const routes = {
        create: isImporterActive
            ? `${baseRoute}pedido-importar`
            : `${baseRoute}pedido-nuevo`,
        list: isImporterActive
            ? `${baseRoute}pedidos-importados`
            : `${baseRoute}pedidos-listado`,
      };
      return routes[action] || `${baseRoute}`;
    },
    getUser() {
      axiosInstance.get(this.$woe_api_uri + '/user')
          .then((response) => {
            this.user = response.data.data.user
            this.$cookies.set("pwa-woe-language", response.data.data.user.default_language);
            sessionStorage.setItem('pwa-woe-user-profile', JSON.stringify(this.user));
            sessionStorage.setItem('pwa-woe-user-type', JSON.stringify(this.user.user_type));
            sessionStorage.setItem('name', JSON.stringify(this.user.name));
            sessionStorage.setItem('pwa-woe-web-order-importador', this.user.web_order_importador);
          })
          .catch((error) => {
            if (error.code == "ERR_BAD_REQUEST") {
              location.href = route + "login";
            }
          });
    },
    getBanners() {
      axiosInstance.get(this.$woe_api_uri + '/banners')
          .then((response) => {
            this.banners = response.data.data.banners
          })
          .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
              location.href = route + "login";
            }
          })
    },
    volverListado() {
      location.href = route + "sold-tos";
      sessionStorage.removeItem('pwa-woe-sold-to');
    },
    updateSoldTosTrue() {
      const soldToItem = sessionStorage.getItem('pwa-woe-sold-to');
      if (soldToItem) {
        this.sold_tos_true = true;
      }
    },
  }
}
</script>
<style>
.section-title a img {
  margin-top: 30px;
}
</style>