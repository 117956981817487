<template>
    <section class="pedido-steps contact-form" v-if="!form_succesfully_submitted">
        <div v-if="country != 'Colombia' && country != 'Argentina' && country != 'Calatin' && country != 'Mexico' && country != 'Uruguay'" class="container">
            <div class="row">
                <div class="col-12">
                    <div class="return d-block">
                        <router-link :to="route">
                            <img src="assets/img/backspace.svg" class="mb-5">
                        </router-link>
                        <router-link :to="route + 'cases-list'">
                            <a href="javascript:void(0)" class="btn-green mr-0 ml-2 btn-new-mobile">{{ $t("my_cases") }}</a>
                        </router-link>
                    </div>
                </div>
                <div class="section-title">
                    <h5 class="pull-left">{{ $t("title") }}</h5>
                </div>
            </div>
            <div class="row">
                
            <form>
                
                <div class="row mt-5">
                    <div class="col-md-3">
                        <div class="form-group" id="input-request">
                            <label>{{ $t("requests") }}</label>
                            <select name="request" v-model="data.request" @change="loadForm($event)">
                                <option v-for="(request,index) in this.requests" :key="index" :value="request.name">{{request.name}}</option>
                            </select>
                            <span class="required-span d-none">{{ $t("requests_mandatory") }}</span>
                        </div>
                    </div>
                    <div class="col-md-2" v-if="showForm">
                        <div class="form-group" id="input-type-of-request">
                            <label>{{ $t("type_of_request") }}</label>
                            <input type="text" name="type_of_request" v-model="data.type_of_request" disabled>
                        </div>
                    </div>
                    <div class="col-md-4" v-if="showForm">
                        <div class="form-group" id="input-account-name">
                            <label>{{ $t("account_name") }}</label>
                            <input type="text" name="account_name" v-model="data.account_name" disabled>
                            <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="showForm && availableFields.description">
                        <div class="form-group" id="input-description">
                            <label>{{ $t("description") }}</label>
                            <select name="description" v-model="data.description" :required="availableFields.description.required">
                                <option v-for="(description,index) in this.descriptions" :key="index" :value="description">{{description}}</option>
                            </select>
                            <span class="required-span d-none">{{ $t("description_mandatory") }}</span>
                        </div>
                    </div> 
                </div>
                <div v-if="showForm">
                <div class="row mt-3">

                    <div class="col-md-4">
                        <div class="form-group" id="input-contact-name">
                            <label>{{ $t("contact_name") }}</label>
                            <input type="text" name="contact_name" v-model="data.contact_name">
                            <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group" id="input-contact-email">
                            <label>{{ $t("contact_email") }}</label>
                            <input type="email" name="contact_email" v-model="data.contact_email">
                            <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="availableFields.po_number">
                        <div class="form-group" id="input-po-number">
                            <label>{{ $t("po_number") }}</label>
                            <VueMultiselect
                                v-model="data.po_number"
                                :options="this.orders"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :internal-search="true"
                                :options-limit="100"
                                placeholder="Type to search"
                                label="purchase_order"
                                track-by="id"
                                :loading="isLoading"
                                :searchable="true"
                                @select="onSelectPoNumber"
                                @search-change="getOrdersByPONumberOldVersion"
                            />
                            <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="availableFields.plant_name">
                        <div class="form-group" id="input-plant-name">
                            <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                            <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                            <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                        </div>
                    </div>
                    
                    <div class="col-md-3" v-if="availableFields.invoice_number">
                        <div class="form-group" id="input-invoice-number">
                            <label>{{ $t("invoice_number") }}</label>
                            <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                            <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="availableFields.lot_number">
                        <div class="form-group" id="input-lot-number">
                            <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                            <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                            <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                        <div class="col-md-12" v-if="availableFields.product_number">
                            <div class="form-group" id="input-product-number">
                                <label title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                <VueMultiselect
                                    v-model="data.productsSelected"
                                    :options="this.products"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :internal-search="true"
                                    :options-limit="100"
                                    placeholder="Type to search"
                                    :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                    track-by="ean"
                                    :loading="isLoading"
                                    :searchable="true"
                                    :multiple="availableFields.product_number_table"
                                    :taggable="false"
                                />
                                <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                            </div>
                        </div>
                        <div class="col-md-12" v-if="availableFields.product_number && availableFields.product_number_table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">{{ $t('product_table_code') }}</th>
                                        <th scope="col">{{ $t('product_table_name') }}</th>
                                        <th class="col-md-2" scope="col">{{ $t('product_table_lot_number') }}</th>
                                        <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                        <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                        <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                        <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                        <td>{{ product?.sku_name }}</td>
                                        <td><input type="text" v-model="product.lot_number"></td>
                                        <td><input type="number" v-model="product.cases"></td>
                                        <td><input type="number" v-model="product.pricePerCase"></td>
                                        <td>${{ calculateTotal(product) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <div class="form-group" id="input-comments">
                            <label>{{ $t('comments') }}</label>
                            <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                            <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                        </div>
                    </div>
                </div>
                <div class="row mt-3"></div>
                <div class="row">
                    <div class="col-md-12">
                        <label>{{ $t('upload_images') }}</label>
                    </div>
                    <div class="col-md-4 mt-3">
                        <input id="att1" name="att1" ref="att1" type="file">
                    </div>
                    <div class="col-md-4 mt-3">
                        <input id="att2" name="att2" ref="att2" type="file">
                    </div>
                    <div class="col-md-4 mt-3">
                        <input id="att3" name="att3" ref="att3" type="file">
                    </div>
                </div>
                <div class="row mt-3"></div>
                <div class="row col-md-11" id="agree">
                    <div class="row col-md-11">
                        <label>
                            <div class="row">
                                <div class="col-md-1">
                                    <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                </div>
                                <div class="col-md-11">
                                    {{ $t('accept_global_privary_policy') }}
                                    <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="row col-md-11">
                        <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                    </div>
                </div>
                <div class="row mt-5">
                    <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                    <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                    <a href="javascript:void(0)" @click="sendForm()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                </div>
                </div>
            </form>
            </div>
        </div>


        <!-- COLOMBIA -->

        <div v-if="country === 'Colombia'" class="container" >
            <div class="row">
                <div class="col-12">
                    <div class="return d-block">
                        <router-link :to="route">
                            <img src="assets/img/backspace.svg" class="mb-5">
                        </router-link>
                        <router-link :to="route + 'cases-list'">
                            <a href="javascript:void(0)" class="btn-green mr-0 ml-2 btn-new-mobile">{{ $t("my_cases") }}</a>
                        </router-link>
                    </div>
                </div>
                <div class="section-title">
                    <h5 class="pull-left">{{ $t("title_col") }}</h5>
                </div>
            </div>
                
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" id="input-request">
                        <label>{{ $t("requests") }}</label>
                        <select name="request" v-model="data.requestForm" @change="showFormByRequestForm(data.requestForm)" class="form-control">
                            <option v-for="(request, index) in requests" :key="index" :value="request.request_form">
                                {{ request.request_form }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-container">

                    <!-- Claim Product -->

                    <form id="form-claim-product" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showCProductForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCProduct($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showCProductForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showCProductForm">
                            <div class="row mt-5" v-if="showCProductForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group" id="input-product-number">
                                            <label class="multiselect-label" title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                            <VueMultiselect
                                                v-model="data.productsSelected"
                                                :options="this.products"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :internal-search="true"
                                                :options-limit="100"
                                                placeholder="Type to search"
                                                :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                                track-by="ean"
                                                :loading="isLoading"
                                                :searchable="true"
                                                :multiple="availableFields.product_number_table"
                                                :taggable="false"
                                                @select="handleSelect"
                                                @remove="handleRemove"
                                            />
                                            <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{{ $t('product_table_code') }}</th>
                                                    <th scope="col" class="col-md-2">{{ $t('product_table_name') }}</th>
                                                    <th v-if="lotNumberField" class="col-md-2" scope="col">{{ $t('lot_number') }}</th>
                                                    <th v-if="sapBatchField" class="col-md-2" scope="col">{{ $t('sap_batch') }}</th>
                                                    <th v-if="beforeDateField" class="col-md-2" scope="col">{{ $t('before_date') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                                    <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                                    <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                                    <td>{{ product?.sku_name }}</td>
                                                    <td v-if="lotNumberField">
                                                        <input type="text" v-model="data.lot_number">
                                                        <span v-if="lot_number_null" class="required-span">{{ $t("lot_number_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="sapBatchField">
                                                        <input type="text" v-model="data.sap_batch">
                                                        <span v-if="sap_batch_null" class="required-span">{{ $t("sap_batch_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="beforeDateField">
                                                        <input type="date" v-model="data.before_date">
                                                        <span v-if="before_date_null" class="required-span">{{ $t("before_date_mandatory") }}</span>
                                                    </td>
                                                    <td><input type="number" v-model="product.cases"></td>
                                                    <td><input type="number" v-model="product.pricePerCase"></td>
                                                    <td>${{ calculateTotal(product) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Claim Service -->

                    <form id="form-claim-service" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showCServiceForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCService($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showCServiceForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showCServiceForm">
                            <div class="row mt-5" v-if="showCServiceForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="lotNumberField">
                                    <div class="form-group" id="input-lot-number">
                                        <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                                        <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                                        <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Query Product -->

                    <form id="form-query-product" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showQProductForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormQProduct($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showQProductForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showQProductForm">
                            <div class="row mt-5" v-if="showQProductForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group" id="input-product-number">
                                            <label class="multiselect-label" title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                            <VueMultiselect
                                                v-model="data.productsSelected"
                                                :options="this.products"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :internal-search="true"
                                                :options-limit="100"
                                                placeholder="Type to search"
                                                :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                                track-by="ean"
                                                :loading="isLoading"
                                                :searchable="true"
                                                :multiple="availableFields.product_number_table"
                                                :taggable="false"
                                                @select="handleSelect"
                                                @remove="handleRemove"
                                            />
                                            <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{{ $t('product_table_code') }}</th>
                                                    <th scope="col" class="col-md-2">{{ $t('product_table_name') }}</th>
                                                    <th v-if="lotNumberField" class="col-md-2" scope="col">{{ $t('lot_number') }}</th>
                                                    <th v-if="sapBatchField" class="col-md-2" scope="col">{{ $t('sap_batch') }}</th>
                                                    <th v-if="beforeDateField" class="col-md-2" scope="col">{{ $t('before_date') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                                    <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                                    <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                                    <td>{{ product?.sku_name }}</td>
                                                    <td v-if="lotNumberField">
                                                        <input type="text" v-model="data.lot_number">
                                                        <span v-if="lot_number_null" class="required-span">{{ $t("lot_number_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="sapBatchField">
                                                        <input type="text" v-model="data.sap_batch">
                                                        <span v-if="sap_batch_null" class="required-span">{{ $t("sap_batch_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="beforeDateField">
                                                        <input type="date" v-model="data.before_date">
                                                        <span v-if="before_date_null" class="required-span">{{ $t("before_date_mandatory") }}</span>
                                                    </td>
                                                    <td><input type="number" v-model="product.cases"></td>
                                                    <td><input type="number" v-model="product.pricePerCase"></td>
                                                    <td>${{ calculateTotal(product) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Query Service -->

                    <form id="form-query-service" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showQServiceForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormQService($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showQServiceForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showQServiceForm">
                            <div class="row mt-5" v-if="showQServiceForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="!showQServiceForm">
                                    <div class="form-group" id="input-lot-number">
                                        <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                                        <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                                        <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Compliments -->

                    <form id="form-compliments" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showComplimentsForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCompliments($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showComplimentsForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showComplimentsForm">
                            <div class="row mt-5" v-if="showComplimentsForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                            </div>                           
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>


        <!-- ARGENTINA -->

        <div v-if="country === 'Argentina'" class="container" >
            <div class="row">
                <div class="col-12">
                    <div class="return d-block">
                        <router-link :to="route">
                            <img src="assets/img/backspace.svg" class="mb-5">
                        </router-link>
                        <router-link :to="route + 'cases-list'">
                            <a href="javascript:void(0)" class="btn-green mr-0 ml-2 btn-new-mobile">{{ $t("my_cases") }}</a>
                        </router-link>
                    </div>
                </div>
                <div class="section-title">
                    <h5 class="pull-left">{{ $t("title_arg") }}</h5>
                </div>
            </div>
                
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" id="input-request">
                        <label>{{ $t("requests") }}</label>
                        <select name="request" v-model="data.requestForm" @change="showFormByRequestForm(data.requestForm)" class="form-control">
                            <option v-for="(request, index) in requests" :key="index" :value="request.request_form">
                                {{ request.request_form }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-container">

                    <!-- Claim Product -->

                    <form id="form-claim-product" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showCProductForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCProduct($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showCProductForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showCProductForm">
                            <div class="row mt-5" v-if="showCProductForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group" id="input-product-number">
                                            <label class="multiselect-label" title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                            <VueMultiselect
                                                v-model="data.productsSelected"
                                                :options="this.products"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :internal-search="true"
                                                :options-limit="100"
                                                placeholder="Type to search"
                                                :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                                track-by="ean"
                                                :loading="isLoading"
                                                :searchable="true"
                                                :multiple="availableFields.product_number_table"
                                                :taggable="false"
                                                @select="handleSelect"
                                                @remove="handleRemove"
                                            />
                                            <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{{ $t('product_table_code') }}</th>
                                                    <th scope="col" class="col-md-2">{{ $t('product_table_name') }}</th>
                                                    <th v-if="lotNumberField" class="col-md-2" scope="col">{{ $t('lot_number') }}</th>
                                                    <th v-if="sapBatchField" class="col-md-2" scope="col">{{ $t('sap_batch') }}</th>
                                                    <th v-if="beforeDateField" class="col-md-2" scope="col">{{ $t('before_date') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                                    <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                                    <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                                    <td>{{ product?.sku_name }}</td>
                                                    <td v-if="lotNumberField">
                                                        <input type="text" v-model="data.lot_number">
                                                        <span v-if="lot_number_null" class="required-span">{{ $t("lot_number_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="sapBatchField">
                                                        <input type="text" v-model="data.sap_batch">
                                                        <span v-if="sap_batch_null" class="required-span">{{ $t("sap_batch_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="beforeDateField">
                                                        <input type="date" v-model="data.before_date">
                                                        <span v-if="before_date_null" class="required-span">{{ $t("before_date_mandatory") }}</span>
                                                    </td>
                                                    <td><input type="number" v-model="product.cases"></td>
                                                    <td><input type="number" v-model="product.pricePerCase"></td>
                                                    <td>${{ calculateTotal(product) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Claim Service -->

                    <form id="form-claim-service" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showCServiceForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCService($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showCServiceForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showCServiceForm">
                            <div class="row mt-5" v-if="showCServiceForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="lotNumberField">
                                    <div class="form-group" id="input-lot-number">
                                        <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                                        <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                                        <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Query Product -->

                    <form id="form-query-product" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showQProductForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormQProduct($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showQProductForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showQProductForm">
                            <div class="row mt-5" v-if="showQProductForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group" id="input-product-number">
                                            <label class="multiselect-label" title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                            <VueMultiselect
                                                v-model="data.productsSelected"
                                                :options="this.products"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :internal-search="true"
                                                :options-limit="100"
                                                placeholder="Type to search"
                                                :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                                track-by="ean"
                                                :loading="isLoading"
                                                :searchable="true"
                                                :multiple="availableFields.product_number_table"
                                                :taggable="false"
                                                @select="handleSelect"
                                                @remove="handleRemove"
                                            />
                                            <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{{ $t('product_table_code') }}</th>
                                                    <th scope="col" class="col-md-2">{{ $t('product_table_name') }}</th>
                                                    <th v-if="lotNumberField" class="col-md-2" scope="col">{{ $t('lot_number') }}</th>
                                                    <th v-if="sapBatchField" class="col-md-2" scope="col">{{ $t('sap_batch') }}</th>
                                                    <th v-if="beforeDateField" class="col-md-2" scope="col">{{ $t('before_date') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                                    <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                                    <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                                    <td>{{ product?.sku_name }}</td>
                                                    <td v-if="lotNumberField">
                                                        <input type="text" v-model="data.lot_number">
                                                        <span v-if="lot_number_null" class="required-span">{{ $t("lot_number_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="sapBatchField">
                                                        <input type="text" v-model="data.sap_batch">
                                                        <span v-if="sap_batch_null" class="required-span">{{ $t("sap_batch_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="beforeDateField">
                                                        <input type="date" v-model="data.before_date">
                                                        <span v-if="before_date_null" class="required-span">{{ $t("before_date_mandatory") }}</span>
                                                    </td>
                                                    <td><input type="number" v-model="product.cases"></td>
                                                    <td><input type="number" v-model="product.pricePerCase"></td>
                                                    <td>${{ calculateTotal(product) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Query Service -->

                    <form id="form-query-service" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showQServiceForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormQService($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showQServiceForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showQServiceForm">
                            <div class="row mt-5" v-if="showQServiceForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="!showQServiceForm">
                                    <div class="form-group" id="input-lot-number">
                                        <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                                        <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                                        <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Compliments -->

                    <form id="form-compliments" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showComplimentsForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCompliments($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showComplimentsForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showComplimentsForm">
                            <div class="row mt-5" v-if="showComplimentsForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                            </div>                           
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>


        <!-- CALATIN -->

        <div v-if="country === 'Calatin'" class="container" >
            <div class="row">
                <div class="col-12">
                    <div class="return d-block">
                        <router-link :to="route">
                            <img src="assets/img/backspace.svg" class="mb-5">
                        </router-link>
                        <router-link :to="route + 'cases-list'">
                            <a href="javascript:void(0)" class="btn-green mr-0 ml-2 btn-new-mobile">{{ $t("my_cases") }}</a>
                        </router-link>
                    </div>
                </div>
                <div class="section-title">
                    <h5 class="pull-left">{{ $t("title_cal") }}</h5>
                </div>
            </div>
                
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" id="input-request">
                        <label>{{ $t("requests") }}</label>
                        <select name="request" v-model="data.requestForm" @change="showFormByRequestForm(data.requestForm)" class="form-control">
                            <option v-for="(request, index) in requests" :key="index" :value="request.request_form">
                                {{ request.request_form }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-container">

                    <!-- Claim Product -->

                    <form id="form-claim-product" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showCProductForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCProduct($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showCProductForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showCProductForm">
                            <div class="row mt-5" v-if="showCProductForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                            <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group" id="input-product-number">
                                            <label class="multiselect-label" title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                            <VueMultiselect
                                                v-model="data.productsSelected"
                                                :options="this.products"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :internal-search="true"
                                                :options-limit="100"
                                                placeholder="Type to search"
                                                :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                                track-by="ean"
                                                :loading="isLoading"
                                                :searchable="true"
                                                :multiple="availableFields.product_number_table"
                                                :taggable="false"
                                                @select="handleSelect"
                                                @remove="handleRemove"
                                            />

                                            <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{{ $t('product_table_code') }}</th>
                                                    <th scope="col" class="col-md-2">{{ $t('product_table_name') }}</th>
                                                    <th v-if="lotNumberField" class="col-md-2" scope="col">{{ $t('lot_number') }}</th>
                                                    <th v-if="sapBatchField" class="col-md-2" scope="col">{{ $t('sap_batch') }}</th>
                                                    <th v-if="beforeDateField" class="col-md-2" scope="col">{{ $t('before_date') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                                    <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                                    <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                                    <td>{{ product?.sku_name }}</td>
                                                    <td v-if="lotNumberField">
                                                        <input type="text" v-model="data.lot_number">
                                                        <span v-if="lot_number_null" class="required-span">{{ $t("lot_number_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="sapBatchField">
                                                        <input type="text" v-model="data.sap_batch">
                                                        <span v-if="sap_batch_null" class="required-span">{{ $t("sap_batch_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="beforeDateField">
                                                        <input type="date" v-model="data.before_date">
                                                        <span v-if="before_date_null" class="required-span">{{ $t("before_date_mandatory") }}</span>
                                                    </td>
                                                    <td><input type="number" v-model="product.cases"></td>
                                                    <td><input type="number" v-model="product.pricePerCase"></td>
                                                    <td>${{ calculateTotal(product) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Claim Service -->

                    <form id="form-claim-service" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showCServiceForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCService($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showCServiceForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showCServiceForm">
                            <div class="row mt-5" v-if="showCServiceForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="lotNumberField">
                                    <div class="form-group" id="input-lot-number">
                                        <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                                        <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                                        <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Query Product -->

                    <form id="form-query-product" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showQProductForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormQProduct($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showQProductForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showQProductForm">
                            <div class="row mt-5" v-if="showQProductForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group" id="input-product-number">
                                            <label class="multiselect-label" title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                            <VueMultiselect
                                                v-model="data.productsSelected"
                                                :options="this.products"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :internal-search="true"
                                                :options-limit="100"
                                                placeholder="Type to search"
                                                :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                                track-by="ean"
                                                :loading="isLoading"
                                                :searchable="true"
                                                :multiple="availableFields.product_number_table"
                                                :taggable="false"
                                                @select="handleSelect"
                                                @remove="handleRemove"
                                            />
                                            <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{{ $t('product_table_code') }}</th>
                                                    <th scope="col" class="col-md-2">{{ $t('product_table_name') }}</th>
                                                    <th v-if="lotNumberField" class="col-md-2" scope="col">{{ $t('lot_number') }}</th>
                                                    <th v-if="sapBatchField" class="col-md-2" scope="col">{{ $t('sap_batch') }}</th>
                                                    <th v-if="beforeDateField" class="col-md-2" scope="col">{{ $t('before_date') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                                    <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                                    <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                                    <td>{{ product?.sku_name }}</td>
                                                    <td v-if="lotNumberField">
                                                        <input type="text" v-model="data.lot_number">
                                                        <span v-if="lot_number_null" class="required-span">{{ $t("lot_number_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="sapBatchField">
                                                        <input type="text" v-model="data.sap_batch">
                                                        <span v-if="sap_batch_null" class="required-span">{{ $t("sap_batch_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="beforeDateField">
                                                        <input type="date" v-model="data.before_date">
                                                        <span v-if="before_date_null" class="required-span">{{ $t("before_date_mandatory") }}</span>
                                                    </td>
                                                    <td><input type="number" v-model="product.cases"></td>
                                                    <td><input type="number" v-model="product.pricePerCase"></td>
                                                    <td>${{ calculateTotal(product) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Query Service -->

                    <form id="form-query-service" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showQServiceForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormQService($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showQServiceForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showQServiceForm">
                            <div class="row mt-5" v-if="showQServiceForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="!showQServiceForm">
                                    <div class="form-group" id="input-lot-number">
                                        <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                                        <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                                        <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Compliments -->

                    <form id="form-compliments" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showComplimentsForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCompliments($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showComplimentsForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showComplimentsForm">
                            <div class="row mt-5" v-if="showComplimentsForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                            </div>                           
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>



        <!-- MEXICO -->

        <div v-if="country === 'Mexico'" class="container" >
            <div class="row">
                <div class="col-12">
                    <div class="return d-block">
                        <router-link :to="route">
                            <img src="assets/img/backspace.svg" class="mb-5">
                        </router-link>
                        <router-link :to="route + 'cases-list'">
                            <a href="javascript:void(0)" class="btn-green mr-0 ml-2 btn-new-mobile">{{ $t("my_cases") }}</a>
                        </router-link>
                    </div>
                </div>
                <div class="section-title">
                    <h5 class="pull-left">{{ $t("title_mex") }}</h5>
                </div>
            </div>
                
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" id="input-request">
                        <label>{{ $t("requests") }}</label>
                        <select name="request" v-model="data.requestForm" @change="showFormByRequestForm(data.requestForm)" class="form-control">
                            <option v-for="(request, index) in requests" :key="index" :value="request.request_form">
                                {{ request.request_form }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-container">

                    <!-- Claim Product -->

                    <form id="form-claim-product" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showCProductForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCProduct($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showCProductForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showCProductForm">
                            <div class="row mt-5" v-if="showCProductForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group" id="input-product-number">
                                            <label class="multiselect-label" title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                            <VueMultiselect
                                                v-model="data.productsSelected"
                                                :options="this.products"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :internal-search="true"
                                                :options-limit="100"
                                                placeholder="Type to search"
                                                :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                                track-by="ean"
                                                :loading="isLoading"
                                                :searchable="true"
                                                :multiple="availableFields.product_number_table"
                                                :taggable="false"
                                                @select="handleSelect"
                                                @remove="handleRemove"
                                            />
                                            <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{{ $t('product_table_code') }}</th>
                                                    <th scope="col" class="col-md-2">{{ $t('product_table_name') }}</th>
                                                    <th v-if="lotNumberField" class="col-md-2" scope="col">{{ $t('lot_number') }}</th>
                                                    <th v-if="sapBatchField" class="col-md-2" scope="col">{{ $t('sap_batch') }}</th>
                                                    <th v-if="beforeDateField" class="col-md-2" scope="col">{{ $t('before_date') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                                    <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                                    <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                                    <td>{{ product?.sku_name }}</td>
                                                    <td v-if="lotNumberField">
                                                        <input type="text" v-model="data.lot_number">
                                                        <span v-if="lot_number_null" class="required-span">{{ $t("lot_number_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="sapBatchField">
                                                        <input type="text" v-model="data.sap_batch">
                                                        <span v-if="sap_batch_null" class="required-span">{{ $t("sap_batch_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="beforeDateField">
                                                        <input type="date" v-model="data.before_date">
                                                        <span v-if="before_date_null" class="required-span">{{ $t("before_date_mandatory") }}</span>
                                                    </td>
                                                    <td><input type="number" v-model="product.cases"></td>
                                                    <td><input type="number" v-model="product.pricePerCase"></td>
                                                    <td>${{ calculateTotal(product) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Claim Service -->

                    <form id="form-claim-service" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showCServiceForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCService($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showCServiceForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showCServiceForm">
                            <div class="row mt-5" v-if="showCServiceForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="lotNumberField">
                                    <div class="form-group" id="input-lot-number">
                                        <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                                        <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                                        <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Query Product -->

                    <form id="form-query-product" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showQProductForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormQProduct($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showQProductForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showQProductForm">
                            <div class="row mt-5" v-if="showQProductForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group" id="input-product-number">
                                            <label class="multiselect-label" title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                            <VueMultiselect
                                                v-model="data.productsSelected"
                                                :options="this.products"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :internal-search="true"
                                                :options-limit="100"
                                                placeholder="Type to search"
                                                :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                                track-by="ean"
                                                :loading="isLoading"
                                                :searchable="true"
                                                :multiple="availableFields.product_number_table"
                                                :taggable="false"
                                                @select="handleSelect"
                                                @remove="handleRemove"
                                            />
                                            <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{{ $t('product_table_code') }}</th>
                                                    <th scope="col" class="col-md-2">{{ $t('product_table_name') }}</th>
                                                    <th v-if="lotNumberField" class="col-md-2" scope="col">{{ $t('lot_number') }}</th>
                                                    <th v-if="sapBatchField" class="col-md-2" scope="col">{{ $t('sap_batch') }}</th>
                                                    <th v-if="beforeDateField" class="col-md-2" scope="col">{{ $t('before_date') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                                    <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                                    <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                                    <td>{{ product?.sku_name }}</td>
                                                    <td v-if="lotNumberField">
                                                        <input type="text" v-model="data.lot_number">
                                                        <span v-if="lot_number_null" class="required-span">{{ $t("lot_number_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="sapBatchField">
                                                        <input type="text" v-model="data.sap_batch">
                                                        <span v-if="sap_batch_null" class="required-span">{{ $t("sap_batch_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="beforeDateField">
                                                        <input type="date" v-model="data.before_date">
                                                        <span v-if="before_date_null" class="required-span">{{ $t("before_date_mandatory") }}</span>
                                                    </td>
                                                    <td><input type="number" v-model="product.cases"></td>
                                                    <td><input type="number" v-model="product.pricePerCase"></td>
                                                    <td>${{ calculateTotal(product) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Query Service -->

                    <form id="form-query-service" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showQServiceForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormQService($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showQServiceForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showQServiceForm">
                            <div class="row mt-5" v-if="showQServiceForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="!showQServiceForm">
                                    <div class="form-group" id="input-lot-number">
                                        <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                                        <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                                        <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Compliments -->

                    <form id="form-compliments" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showComplimentsForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCompliments($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showComplimentsForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showComplimentsForm">
                            <div class="row mt-5" v-if="showComplimentsForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                            </div>                           
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>



        <!-- URUGUAY -->

        <div v-if="country === 'Uruguay'" class="container" >
            <div class="row">
                <div class="col-12">
                    <div class="return d-block">
                        <router-link :to="route">
                            <img src="assets/img/backspace.svg" class="mb-5">
                        </router-link>
                        <router-link :to="route + 'cases-list'">
                            <a href="javascript:void(0)" class="btn-green mr-0 ml-2 btn-new-mobile">{{ $t("my_cases") }}</a>
                        </router-link>
                    </div>
                </div>
                <div class="section-title">
                    <h5 class="pull-left">{{ $t("title_uru") }}</h5>
                </div>
            </div>
                
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" id="input-request">
                        <label>{{ $t("requests") }}</label>
                        <select name="request" v-model="data.requestForm" @change="showFormByRequestForm(data.requestForm)" class="form-control">
                            <option v-for="(request, index) in requests" :key="index" :value="request.request_form">
                                {{ request.request_form }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-container">

                    <!-- Claim Product -->

                    <form id="form-claim-product" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showCProductForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCProduct($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showCProductForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showCProductForm">
                            <div class="row mt-5" v-if="showCProductForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group" id="input-product-number">
                                            <label class="multiselect-label" title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                            <VueMultiselect
                                                v-model="data.productsSelected"
                                                :options="this.products"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :internal-search="true"
                                                :options-limit="100"
                                                placeholder="Type to search"
                                                :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                                track-by="ean"
                                                :loading="isLoading"
                                                :searchable="true"
                                                :multiple="availableFields.product_number_table"
                                                :taggable="false"
                                                @select="handleSelect"
                                                @remove="handleRemove"
                                            />
                                            <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{{ $t('product_table_code') }}</th>
                                                    <th scope="col" class="col-md-2">{{ $t('product_table_name') }}</th>
                                                    <th v-if="lotNumberField" class="col-md-2" scope="col">{{ $t('lot_number') }}</th>
                                                    <th v-if="sapBatchField" class="col-md-2" scope="col">{{ $t('sap_batch') }}</th>
                                                    <th v-if="beforeDateField" class="col-md-2" scope="col">{{ $t('before_date') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                                    <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                                    <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                                    <td>{{ product?.sku_name }}</td>
                                                    <td v-if="lotNumberField">
                                                        <input type="text" v-model="data.lot_number">
                                                        <span v-if="lot_number_null" class="required-span">{{ $t("lot_number_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="sapBatchField">
                                                        <input type="text" v-model="data.sap_batch">
                                                        <span v-if="sap_batch_null" class="required-span">{{ $t("sap_batch_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="beforeDateField">
                                                        <input type="date" v-model="data.before_date">
                                                        <span v-if="before_date_null" class="required-span">{{ $t("before_date_mandatory") }}</span>
                                                    </td>
                                                    <td><input type="number" v-model="product.cases"></td>
                                                    <td><input type="number" v-model="product.pricePerCase"></td>
                                                    <td>${{ calculateTotal(product) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Claim Service -->

                    <form id="form-claim-service" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showCServiceForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCService($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showCServiceForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showCServiceForm">
                            <div class="row mt-5" v-if="showCServiceForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="lotNumberField">
                                    <div class="form-group" id="input-lot-number">
                                        <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                                        <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                                        <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Query Product -->

                    <form id="form-query-product" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showQProductForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormQProduct($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showQProductForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showQProductForm">
                            <div class="row mt-5" v-if="showQProductForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="form-group" id="input-product-number">
                                            <label class="multiselect-label" title="{{ $t('product_number_text') }}">{{ $t('product_number') }}</label>
                                            <VueMultiselect
                                                v-model="data.productsSelected"
                                                :options="this.products"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :internal-search="true"
                                                :options-limit="100"
                                                placeholder="Type to search"
                                                :custom-label="({ean, sku_name, material }) => `${ean} - ${sku_name} - ${material}`"
                                                track-by="ean"
                                                :loading="isLoading"
                                                :searchable="true"
                                                :multiple="availableFields.product_number_table"
                                                :taggable="false"
                                                @select="handleSelect"
                                                @remove="handleRemove"
                                            />
                                            <span class="required-span d-none">{{ $t('product_number_mandatory') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{{ $t('product_table_code') }}</th>
                                                    <th scope="col" class="col-md-2">{{ $t('product_table_name') }}</th>
                                                    <th v-if="lotNumberField" class="col-md-2" scope="col">{{ $t('lot_number') }}</th>
                                                    <th v-if="sapBatchField" class="col-md-2" scope="col">{{ $t('sap_batch') }}</th>
                                                    <th v-if="beforeDateField" class="col-md-2" scope="col">{{ $t('before_date') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_cases') }}</th>
                                                    <th class="col-md-2" scope="col">{{ $t('product_table_price') }}</th>
                                                    <th class="col-md-1" scope="col">{{ $t('product_table_total') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product) in data.productsSelected" :key="product?.ean">
                                                    <th scope="row">{{ product?.ean }} - {{ product?.material }}</th>
                                                    <td>{{ product?.sku_name }}</td>
                                                    <td v-if="lotNumberField">
                                                        <input type="text" v-model="data.lot_number">
                                                        <span v-if="lot_number_null" class="required-span">{{ $t("lot_number_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="sapBatchField">
                                                        <input type="text" v-model="data.sap_batch">
                                                        <span v-if="sap_batch_null" class="required-span">{{ $t("sap_batch_mandatory") }}</span>
                                                    </td>
                                                    <td v-if="beforeDateField">
                                                        <input type="date" v-model="data.before_date">
                                                        <span v-if="before_date_null" class="required-span">{{ $t("before_date_mandatory") }}</span>
                                                    </td>
                                                    <td><input type="number" v-model="product.cases"></td>
                                                    <td><input type="number" v-model="product.pricePerCase"></td>
                                                    <td>${{ calculateTotal(product) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Query Service -->

                    <form id="form-query-service" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showQServiceForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormQService($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showQServiceForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showQServiceForm">
                            <div class="row mt-5" v-if="showQServiceForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" id="input-po-number">
                                        <label>{{ $t("po_number") }}</label>
                                        <VueMultiselect
                                            v-model="data.po_number"
                                            :options="orders"
                                            :close-on-select="true"
                                            :clear-on-select="false"
                                            :internal-search="true"
                                            :options-limit="100"
                                            placeholder="Type to search"
                                            label="label"
                                            track-by="id"
                                            :loading="isLoading"
                                            :searchable="true"
                                            @select="onSelectPoNumber"
                                            @search-change="getOrdersByPONumber"
                                        />
                                        <span class="required-span d-none">{{ $t("po_number_mandatory") }}</span>
                                    </div>
                                </div>
                            </div>

                                
                                <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="form-group" id="input-plant-name">
                                        <label title="{{ $t('plant_name_text') }}">{{ $t('plant_name') }}</label>                            
                                        <input type="text" name="plant_name" v-model="data.plant_name" :required="availableFields.plant_name.required" disabled>
                                        <span class="required-span d-none">{{ $t('plant_name_mandatory') }}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group" id="input-invoice-number">
                                        <label>{{ $t("invoice_number") }}</label>
                                        <input type="text" name="invoice-number" v-model="data.invoice_number" :required="availableFields.invoice_number.required">
                                        <span class="required-span d-none">{{ $t("invoice_number_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="!showQServiceForm">
                                    <div class="form-group" id="input-lot-number">
                                        <label title="{{ $t('lot_number_text') }}">{{ $t('lot_number') }}</label>
                                        <input type="text" name="lot_number" v-model="data.lot_number" :required="availableFields.lot_number.required">
                                        <span class="required-span d-none">{{ $t('lot_number_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>


                    <!-- Compliments -->

                    <form id="form-compliments" class="form-dynamic">
                        <div class="row mt-5">
                            <div class="col-md-6" v-if="showComplimentsForm">
                                <div class="form-group" id="input-request">
                                    <label>{{ $t("type_of_request") }}</label>
                                    <select name="description" v-model="data.requestTypeDescForm" @change="loadFormCompliments($event)" class="form-control">
                                        <option v-for="(desc, index) in filteredDescriptions" :key="index" :value="desc.description_form">
                                            {{ desc.description_form }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="showComplimentsForm">
                                <div class="form-group" id="input-account-name">
                                    <label>{{ $t("account_name") }}</label>
                                    <input type="text" name="account_name" v-model="data.account_name" disabled>
                                    <span class="required-span d-none">{{ $t("account_name_mandatory") }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="showComplimentsForm">
                            <div class="row mt-5" v-if="showComplimentsForm">
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-name">
                                        <label>{{ $t("contact_name") }}</label>
                                        <input type="text" name="contact_name" v-model="data.contact_name">
                                        <span class="required-span d-none">{{ $t("contact_name_mandatory") }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" id="input-contact-email">
                                        <label>{{ $t("contact_email") }}</label>
                                        <input type="email" name="contact_email" v-model="data.contact_email" @input="validateEmail">
                                        <span class="required-span d-none">{{ $t("contact_email_mandatory") }}</span>
                                        <span style="color: #FF4C82; padding-top: 8px;" :class="{'d-none': isEmailValid}">{{ $t("contact_email_format") }}</span>
                                    </div>
                                </div>
                            </div>                           
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group" id="input-comments">
                                        <label>{{ $t('comments') }}</label>
                                        <textarea type="text" name="comments" rows="6" v-model="data.comments"></textarea>
                                        <span class="required-span d-none">{{ $t('comments_mandatory') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ $t('upload_images') }}</label>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att1" name="att1" ref="att1" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att2" name="att2" ref="att2" type="file">
                                </div>
                                <div class="col-md-4 mt-3">
                                    <input id="att3" name="att3" ref="att3" type="file">
                                </div>
                            </div>
                            <div class="row mt-3"></div>
                            <div class="row col-md-11" id="agree">
                                <div class="row col-md-11">
                                    <label>
                                        <div class="row">
                                            <div class="col-md-1">
                                                <input class="mr-1" type="checkbox" name="agree_privacy_policy" id="agree_privacy_policy" required> 
                                            </div>
                                            <div class="col-md-11">
                                                {{ $t('accept_global_privary_policy') }}
                                                <a target="_blank" href="{{ $t('mccain_privacy_policy_link') }}">{{ $t('accept_terms_and_conditions') }}</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="row col-md-11">
                                    <span class="required-span d-none">{{ $t('accept_terms_and_conditions') }}</span>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <a :href="route" class="btn-white">{{ $t("buttons.back_to_home") }}</a>
                                <a @click="cancel()" href="javascript:void(0)" class="btn-white">{{ $t("buttons.cancel") }}</a>
                                <a href="javascript:void(0)" @click="sendFormNew()" class="btn-send-form btn-green mr-0 ml-2">{{ (sendingForm) ? $t("buttons.sending") : $t("buttons.send") }} <div v-if="sendingForm" class="spinner-loading"></div></a>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>


    </section>
    <section class="pedido-enviado contact-form" v-if="form_succesfully_submitted">
        <div class="container">
            <div class="row" >
                <div class="col-12">
                    <span class="check"><img src="assets/img/check.svg"></span>
                    <div class="listo">
                        <h3>¡{{ $t("ready") }}!</h3>
                        <p>{{ $t("text") }}</p>
                        <a href="javascript:void(0);" @click="reload()" class="btn-yellow">
                            {{ $t("buttons.ok") }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
</template>
<script>
import {route} from "../../enviroment.js"
import {ref} from 'vue';
import $ from 'jquery'
// import axios from 'axios'
import axiosInstance from '../../main';
import authMixin from "@/mixins/authMixin";

export default {
        name: "ContactForm",
        i18n: {
            messages: {
                en: {
                    "title": "McCain Customer Contact Form",
                    "title_col": "McCain Customer Contact Form Colombia",
                    "title_arg": "McCain Customer Contact Form Argentina",
                    "title_cal": "McCain Customer Contact Form Calatin",
                    "title_mex": "McCain Customer Contact Form México",
                    "title_uru": "McCain Customer Contact Form Uruguay",
                    "type_of_request": "Type of request",
                    "type_of_request_mandatory": "Type of request is mandatory",
                    "type_of_communication": "Type of communication",
                    "type_of_communication_mandatory": "Type of communication is mandatory",
                    "contact_name": "Customer name",
                    "contact_name_mandatory": "Name is mandatory",
                    "contact_email": "Customer email",
                    "contact_email_mandatory": "Email is mandatory",
                    "contact_email_format": "Incorrect email format",
                    "account_name": "Customer account name",
                    "account_name_mandatory": "Account name is mandatory",
                    "product_number_text": "Please indicate the full number of the product found on the front of the package, including the bag size and type of cut, for example, Home Chip Crinkle Cut.",
                    "product_number": "Product name",
                    "product_number_mandatory": "Product number is mandatory",
                    "comments": "Your comments",
                    "comments_mandatory": "Comments are mandatory",
                    "lot_number_text": "Please indicate the lot number that is usually printed on the back of the package.",
                    "lot_number": "Lot number",
                    "lot_number_mandatory": "Lot number is mandatory",
                    "upload_images": "Upload images",
                    "accept_global_privary_policy": "Please check the box to confirm that you agree with the treatment of personal data according to our Global Privacy Policy.",
                    "accept_terms_and_conditions": "You must accept the terms and conditions.",
                    "form_succesfully_submitted": "The form was successfully submitted.",
                    "requests": "Request",
                    "po_number": "PO Number",
                    "po_number_mandatory": "PO Number is mandatory",
                    "plant_name": "Plant Name",
                    "plant_name_mandatory": "Plant Name is mandatory",
                    "invoice_number": "Invoice Number",
                    "invoice_number_mandatory": "Invoice Number is mandatory",
                    "sap_batch": "SAP Batch",
                    "sap_batch_mandatory": "SAP Batch is mandatory",
                    "before_date": "Expiration date",
                    "before_date_mandatory": "Expiration date is mandatory",
                    "description": "Description",
                    "description_mandatory": "Description is mandatory",
                    "requests_mandatory": "Request is mandatory",
                    "ready": "Ready",
                    "text": "Your request has been sent to McCain for review.",
                    "product_table_code": "Code",
                    "product_table_name": "Name",
                    "product_table_cases": "Cases",
                    "product_table_price": "Price p/case",
                    "product_table_total": "Total",
                    "product_table_lot_number": "Lot Number",
                    "my_cases": "My Cases"
                },
                es: {
                    "title": "Formulario de Contacto de Clientes McCain",
                    "title_col": "Formulario de Contacto de Clientes McCain Colombia",
                    "title_arg": "Formulario de Contacto de Clientes McCain Argentina",
                    "title_cal": "Formulario de Contacto de Clientes McCain Calatin",
                    "title_mex": "Formulario de Contacto de Clientes McCain México",
                    "title_uru": "Formulario de Contacto de Clientes McCain Uruguay",
                    "type_of_communication": "Tipo de comunicación",
                    "type_of_communication_mandatory": "El tipo de comunicacion es obligatorio",
                    "contact_name": "Nombre del cliente",
                    "contact_name_mandatory": "El nombre es obligatorio",
                    "contact_email": "Email del cliente",
                    "contact_email_mandatory": "El email es obligatorio",
                    "contact_email_format": "Formato incorrecto de email",
                    "account_name": "Nombre de cuenta del cliente",
                    "account_name_mandatory": "El nombre de cuenta es obligatorio",
                    "product_number_text": "Por favor, indique el nombre completo del producto que se encuentra en la parte frontal del paquete, indicando el tamaño de la bolsa y el tipo de corte, por ejemplo, Home Chip Crinkle Cut.",
                    "product_number": "Nombre del producto",
                    "product_number_mandatory": "El nombre del producto es obligatorio",
                    "comments": "Sus comentarios",
                    "comments_mandatory": "Los comentarios son obligatorios",
                    "lot_number_text": "Por favor, indique el número de lote que se imprime normalmente en la parte posterior del paquete.",
                    "lot_number": "Número de lote",
                    "lot_number_mandatory": "El numero de lote es obligatorio",
                    "upload_images": "Subir imágenes",
                    "accept_global_privary_policy": "Por favor, marque la casilla para confirmar que está de acuerdo con el tratamiento de los datos personales de acuerdo con nuestra Política Global de Privacidad",
                    "accept_terms_and_conditions": "Debe aceptar los terminos y condiciones",
                    "form_succesfully_submitted": "El formulario se envio correctamente",
                    "requests": "Solicitud",
                    "type_of_request": "Tipo de solicitud",
                    "type_of_request_mandatory": "El tipo de solicitud es obligatorio",
                    "po_number": "Número de PO",
                    "po_number_mandatory": "El número de PO es obligatorio",
                    "plant_name": "Nombre de la planta",
                    "plant_name_mandatory": "El nombre de la planta es obligatorio",
                    "invoice_number": "Número de factura",
                    "invoice_number_mandatory": "El número de factura es obligatorio",
                    "sap_batch": "Lote SAP",
                    "sap_batch_mandatory": "El Lote SAP es obligatorio",
                    "before_date": "Fecha de expiración",
                    "before_date_mandatory": "La Fecha de expiración es obligatoria",
                    "description": "Descripción",
                    "description_mandatory": "La descripción es obligatoria",
                    "requests_mandatory": "La solicitud es obligatoria",
                    "ready": "Listo",
                    "text": "Tu solicitud fue enviada a McCain para revisión.",
                    "product_table_code": "Codigo",
                    "product_table_name": "Nombre",
                    "product_table_cases": "Unidades",
                    "product_table_price": "Precio p/unidad",
                    "product_table_total": "Total",
                    "product_table_lot_number": "Número de lote",
                    "my_cases": "Mis Casos"
                }
            }
        },
        setup() {
            const month = ref({ 
                month: new Date().getMonth(),
                year: new Date().getFullYear()
            });
            return {
                month,
            }
        },
        data() {
            return {
                route:route,
                data: {
                    type_of_request: '',
                    contact_name: '',
                    contact_email: '',
                    account_name: '',
                    productsSelected: [],
                    comments: '',
                    lot_number: '',
                    invoice_number: '', 
                    plant_name: '',
                    po_number: '',
                    plant_number: '',
                    reason_c: '',
                    request_c: '',
                    record_type_id: '',
                    requestForm: null,
                    requestTypeDescForm: null,
                    owner_id: null,
                    queue_id: null,
                    queue: null,
                    description_form: null,
                    sap_batch: null,
                    before_date: null,
                    isCaseCostObject: true,
                    subregion: '',
                    thalamus: true,
                    bussiness_segment: '',
                    contact_channel: '',
                    contact_channel_detail: '',
                    customer_group: '',
                    case_region: '',
                },
                availableFields: this.defaultForm(),
                requests: [],
                selectedRequest: null,
                showForm: false,
                showCProductForm: false,
                showCServiceForm: false,
                showQProductForm: false,
                showQServiceForm: false,
                showComplimentsForm: false,
                isLoading: true,
                sendingForm: false,
                orders: [],
                products: [],
                descriptions: [],
                form_succesfully_submitted: false,
                country: null,
                salesforceData: [],
                filteredDescriptions: [],
                default_language: 'english',
                isEmailValid: true,
                lotNumberField: false,
                sapBatchField: false,
                beforeDateField: false,
                before_date_null: false,
                sap_batch_null: false,
                lot_number_null: false,
            }
        },
        mounted() {
            this.getCountry();
            if (this.country === 'Colombia' || this.country === 'Argentina' || this.country === 'Mexico' || this.country === 'Uruguay'){
                this.fetchSalesforceReasonCode().then(() => {
                    this.getRequestsNew();
                });
            }else if(this.country === 'Calatin'){
                this.fetchSalesforceReasonCodeCalatin().then(() => {
                    this.getRequestsNewCalatin();
                });
            }else{
                this.getRequests();
            }
        },
        mixins: [authMixin],
        async created(){
            const valid = await this.validateUserAndSoldTo();
            if (valid) {
                this.getUser()
                this.getOrdersByPONumber()
            }
        },
        methods: {
            fetchSalesforceReasonCode() {
                return axiosInstance.get(`${this.$woe_api_uri}/contact/reason-code`)
                    .then((response) => {
                        this.salesforceData = response.data;
                    })
                    .catch((error) => {
                        console.error("Error fetching Salesforce data:", error);
                        this.salesforceData = [];
                    });
            },
            fetchSalesforceReasonCodeCalatin() {
                return axiosInstance.get(`${this.$woe_api_uri}/contact/reason-code`)
                    .then((response) => {
                        if (this.default_language === 'es' || this.default_language === 'spanish') {
                            this.salesforceData = response.data.filter(item => item.lang.trim() === 'es');
                        } else if (this.default_language === 'en' || this.default_language === 'english') {
                            this.salesforceData = response.data.filter(item => item.lang.trim() === 'en');
                        } else {
                            this.salesforceData = response.data;
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching Salesforce data:", error);
                        this.salesforceData = [];
                    });
            },
            getCountry(){
                const country = sessionStorage.getItem('country');
                this.country = country;
            },
            calculateTotal(product) {
                const cases = parseFloat(product.cases) || 0;
                const pricePerCase = parseFloat(product.pricePerCase) || 0;
                const total = cases * pricePerCase;
                return total.toFixed(2);
            },
            getUser(){
                axiosInstance.get(this.$woe_api_uri + '/user'
                ).then((response) => {        
                    this.user = response.data.data.user
                    const languageMap = {
                        'english': 'en',
                        'spanish': 'es'
                    };
                    this.default_language = languageMap[response.data.data.user.default_language.toLowerCase()] || 'en';
                    this.$i18n.locale = this.default_language;
                }).catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    } else {
                        this.showAlert('warning',`${this.$t('caution')}!`, error.response.data.error.message);
                    }
                });
            },
            showAlert(type, title, message) {
                this.$refs.alert.showAlert(
                    type, // There are 4 types of alert: success, info, warning, error
                    message, // Message of the alert
                    title, // Header of the alert
                    { 
                        iconSize: 35, // Size of the icon (px)
                        iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                        position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                    } 
                )
            },
            getRequests() {
                this.requests = [
                    { 
                        id: 2,
                        name: "Accounting Services",
                        type_of_request: "Delivery",
                        description: [
                            'Account Statements',
                            'Credit Note Request',
                            'Credit Limit'
                        ]
                    },
                    { 
                        id: 3,
                        name: "Compliance/Customs",
                        type_of_request: "Delivery",
                        description: [
                            'Compliance Audit',
                            'Customs and International Regulations',
                            'Environmental query',
                            'Free Trade Agreements',
                            'Import/Export Query',
                            'Policies and Procedures Request',
                            'Product Specification',
                            'Quality Certifications',
                            'Sustainability'
                        ]
                    },
                    { 
                        id: 4,
                        name: "Customer Service",
                        type_of_request: "Delivery",
                        description: [
                            'Delivery Query',
                            'Digital Platforms', 
                            'Document Inquiry',
                            'Purchase Orders',
                            'Transportation'
                        ]
                    },
                    { 
                        id: 5,
                        name: "Food Safety",
                        type_of_request: "Complaint",
                        description: [
                            'Product safety',
                            'Production / Expiration date',
                            'Product Compliance',
                            'Shelf life'
                        ]
                    },
                    { 
                        id: 6,
                        name: "Logistics Claims",
                        type_of_request: "Complaint",
                        description: [
                            'Damages',
                            'Demurrages',
                            'Product Quality',
                            'Shortages',
                            'Temperature abuse',
                        ]
                    },
                    { 
                        id: 7,
                        name: "Commercial Inquiries",
                        type_of_request: "Delivery",
                        description: [
                            'Culinary Support',
                            'Demand Forecasting',
                            'Innovation',
                            'Price Inquiry',
                            'Product Tasting/Trainnings',
                            'Samples'
                        ]
                    },
                    { 
                        id: 8,
                        name: "Compliment",
                        type_of_request: "Compliment",
                        description: []
                    }
                ];
            },
            getRequestsNew() {
                if (this.salesforceData && this.salesforceData.length > 0) {
                    const uniqueDescriptions = new Set();
                    this.requests = this.salesforceData
                        .filter(item => {
                            if (uniqueDescriptions.has(item.request_form)) {
                                return false;
                            } else {
                                uniqueDescriptions.add(item.request_form);
                                return true;
                            }
                        })
                        .map(item => ({
                            id: item.id,
                            request_form: item.request_form,
                            description_form: item.description_form,
                            record_type_id: item.record_type_id,
                            owner_id: item.owner_id,
                            reason_c: item.reason_c,
                            request_c: item.request_c,
                            country: item.country
                        }));
                } else {
                    this.requests = [];
                }
            },
            getRequestsNewCalatin() {
                if (this.salesforceData && this.salesforceData.length > 0) {
                    const uniqueDescriptions = new Set();
                    this.requests = this.salesforceData
                        .filter(item => {
                            if (uniqueDescriptions.has(item.request_form)) {
                                return false;
                            } else {
                                uniqueDescriptions.add(item.request_form);
                                return true;
                            }
                        })
                        .map(item => ({
                            id: item.id,
                            request_form: item.request_form,
                            description_form: item.description_form,
                            record_type_id: item.record_type_id,
                            owner_id: item.owner_id,
                            reason_c: item.reason_c,
                            request_c: item.request_c,
                            country: item.country
                        }));
                } else {
                    this.requests = [];
                }
            },
            validateForm(){
                let errors = 0;

                if(this.sapBatchField === true || this.beforeDateField === true || this.lotNumberField === true){
                    if(this.data.lot_number === ''){
                        this.lot_number_null = true;
                        errors++;
                    }else{
                        this.lot_number_null = false;
                    }

                    if(this.data.sap_batch === ''){
                        this.sap_batch_null = true;
                        errors++;
                    }else{
                        this.sap_batch_null = false;
                    }

                    if(this.data.before_date === ''){
                        this.before_date_null = true;
                        errors++;
                    }else{
                        this.before_date_null = false;
                    }
                }

                $('#input-contact-name .required-span').addClass('d-none');
                $('#input-contact-email .required-span').addClass('d-none');
                $('#input-description .required-span').addClass('d-none');
                $('#input-po-number .required-span').addClass('d-none');
                $('#input-invoice-number .required-span').addClass('d-none');
                $('#input-sap-batch .required-span').addClass('d-none');
                $('#input-lot-number .required-span').addClass('d-none');
                $('#input-comments .required-span').addClass('d-none');
                $('#agree .required-span').addClass('d-none');

                //Valido contact name
                if (this.data.contact_name == ""){
                    $('#input-contact-name .required-span').removeClass('d-none')
                    $('#input-contact-name input').addClass('required')
                    errors++;
                } else {
                    $('#input-contact-name .required-span').addClass('d-none')
                    $('#input-contact-name input').removeClass('required')
                }
                
                //Valido input-contact-email
                if (this.data.contact_email == ""){
                    $('#input-contact-email .required-span').removeClass('d-none')
                    $('#input-contact-email input').addClass('required')
                    errors++;
                } else {
                    $('#input-contact-email .required-span').addClass('d-none')
                    $('#input-contact-email input').removeClass('required')
                }

                //Valido input-description
                if (this.availableFields?.description?.required && (this.data.description == undefined || this.data.description == "")){
                    $('#input-description .required-span').removeClass('d-none')
                    $('#input-description select').addClass('required')
                    errors++;
                } else {
                    $('#input-description .required-span').addClass('d-none')
                    $('#input-description select').removeClass('required')
                }

                //po number
                if (this.availableFields?.po_number?.required && (this.data.po_number == undefined || this.data.po_number == "")){
                    $('#input-po-number .required-span').removeClass('d-none')
                    $('#input-po-number .multiselect__tags').addClass('required')
                    errors++;
                } else {
                    $('#input-po-number .required-span').addClass('d-none')
                    $('#input-po-number .multiselect__tags').removeClass('required')
                }

                //input-product-number
                if (this.availableFields?.product_number?.required && (this.data.productsSelected == undefined || this.data.productsSelected == "")){
                    $('#input-product-number .required-span').removeClass('d-none')
                    $('#input-product-number .multiselect__tags').addClass('required')
                    errors++;
                } else {
                    $('#input-product-number .required-span').addClass('d-none')
                    $('#input-product-number .multiselect__tags').removeClass('required')
                }

                //invoice_number
                if (this.availableFields?.invoice_number?.required && this.data.invoice_number == ""){
                    $('#input-invoice-number .required-span').removeClass('d-none')
                    $('#input-invoice-number input').addClass('required')
                    errors++;
                } else {
                    $('#input-invoice-number .required-span').addClass('d-none')
                    $('#input-invoice-number input').removeClass('required')
                }

                //lot_number
                if (this.availableFields?.lot_number?.required && this.data.lot_number == ""){
                    $('#input-lot-number .required-span').removeClass('d-none')
                    $('#input-lot-number input').addClass('required')
                    errors++;
                } else {
                    $('#input-lot-number .required-span').addClass('d-none')
                    $('#input-lot-number input').removeClass('required')
                }

                //Valido input-comments
                if (this.data.comments == ""){
                    $('#input-comments .required-span').removeClass('d-none')
                    $('#input-comments textarea').addClass('required')
                    errors++;
                } else {
                    $('#input-comments .required-span').addClass('d-none')
                    $('#input-comments textarea').removeClass('required')
                }

                //Valido gree
                if (!$('#agree_privacy_policy').is(':checked')) {
                    $('#agree .required-span').removeClass('d-none')
                    $('#agree input').addClass('required')
                    errors++;
                } else {
                    $('#agree .required-span').addClass('d-none')
                    $('#agree input').removeClass('required')
                }

                if (errors>0) {
                    return false;
                }

                return true;
            },

            sendForm(){
                if (!this.validateForm()) {
                    return false;
                }
                if (this.sendingForm) {
                    $('.btn-send-form').addClass('disabled');
                    return false;
                }
                this.sendingForm = true;
                let dataForm = new FormData();
                Object.keys(this.data).forEach((key) => {
                    if (key == 'po_number') {
                        dataForm.append(key, this.data.po_number.id);
                    } else if (key == 'productsSelected') {
                        let products = [];
                        if (this.data.request == 'Logistics Claims') {
                            products = this.data[key].map(product => {
                                return {
                                    material: product.material,
                                    ean: product.ean,
                                    cases: product.cases,
                                    pricePerCase: product.pricePerCase,
                                    lot_number: product.lot_number,
                                }
                            });
                        } else {
                            products = [{
                                material: this.data.productsSelected.material,
                                ean: this.data.productsSelected.ean,
                                cases: this.data.productsSelected.cases,
                                pricePerCase: this.data.productsSelected.pricePerCase,
                                lot_number: this.data.lot_number,
                            }];
                        }
                        dataForm.append(key, JSON.stringify(products));
                    } else {
                        dataForm.append(key, this.data[key]);
                    }
                });
                dataForm.append(`att1`, this.$refs.att1.files[0]);
                dataForm.append(`att2`, this.$refs.att2.files[0]);
                dataForm.append(`att3`, this.$refs.att3.files[0]);
                axiosInstance.post(this.$woe_api_uri + '/contact', dataForm,
                ).then(() => {
                    this.form_succesfully_submitted = true;
                })
                .catch((error) => {
                    this.showAlert('warning',`${this.$t('caution')}!`, error?.response?.data?.error?.message ?? this.$t("unexpected_error"));
                }).finally(() => {
                    this.sendingForm = false;
                });
            },

            sendFormNew(){
                if (!this.validateForm()) {
                    return false;
                }
                if (this.sendingForm) {
                    $('.btn-send-form').addClass('disabled');
                    return false;
                }
                this.sendingForm = true;

                this.data.subregion = this.country;
                this.data.thalamus = true;
                this.data.bussiness_segment = 'Food Service';
                this.data.contact_channel = 'Website';
                this.data.contact_channel_detail = 'Webform FoodService';
                this.data.customer_group =  'Distributor';
                this.data.case_region = 'LATAM';

                let dataForm = new FormData();
                Object.keys(this.data).forEach((key) => {
                    if (key == 'po_number') {
                        const poValue = this.data.po_number.purchase_order || this.data.po_number.order_number;
                        dataForm.append(key, poValue);
                    } else if (key == 'productsSelected') {
                        let products = [];
                        if (['Claim - Product', 'Query - Product', 'Reclamo - Producto', 'Consulta - Producto'].includes(this.data.request_form)) {
                            products = this.data[key].map(product => {
                                return {
                                    material: product.material,
                                    ean: product.ean,
                                    cases: product.cases,
                                    pricePerCase: product.pricePerCase,
                                    unit: product.unit,
                                    plant_name: this.data.plant_name,
                                    lot_number: this.data.lot_number,
                                    sap_batch: this.data.sap_batch,
                                    before_date: this.data.before_date,
                                }
                            });
                        } else {
                            products = [{
                                material: this.data.productsSelected.material,
                                ean: this.data.productsSelected.ean,
                                cases: this.data.productsSelected.cases,
                                pricePerCase: this.data.productsSelected.pricePerCase,
                                unit: this.data.productsSelected.unit,
                                plant_name: this.data.plant_name,
                                lot_number: this.data.lot_number,
                                sap_batch: this.data.sap_batch,
                                before_date: this.data.before_date,
                            }];
                        }

                        dataForm.append(key, JSON.stringify(products));
                    } else {
                        dataForm.append(key, this.data[key]);
                    }
                });
                dataForm.append(`att1`, this.$refs.att1.files[0]);
                dataForm.append(`att2`, this.$refs.att2.files[0]);
                dataForm.append(`att3`, this.$refs.att3.files[0]);
                axiosInstance.post(this.$woe_api_uri + '/contactNew', dataForm,
                ).then(() => {
                    this.form_succesfully_submitted = true;
                })
                .catch((error) => {
                    this.showAlert('warning',`${this.$t('caution')}!`, error?.response?.data?.error?.message ?? this.$t("unexpected_error"));
                }).finally(() => {
                    this.sendingForm = false;
                });
            },

            getRequestById(id) {
                return this.requests.find((request) => request.id == id);
            },
            getRequestByName(name) {
                return this.requests.find((request) => request.name == name);
            },
            loadForm() {
                const request = this.getRequestByName(event.target.value);
                this.data = {...this.data, ...this.defaultValues()};
                this.availableFields = this.getFormFieldsByRequestId(request.id);               
                this.products = [];
                this.data.type_of_request = request.type_of_request;
                this.data.account_name = this.user?.name;
                this.descriptions = request.description;                
                this.showForm = true;
            },

            showFormByRequestForm(desc) {

                desc = desc.trim();

                if(desc === 'Claim - Product' || desc === 'Reclamo - Producto'){
                    this.data = {
                        ...this.defaultValues(),
                        requestForm: desc,
                        requestTypeDescForm: null,
                    };
                    
                    this.filteredDescriptions = [];
                    this.showCProductForm = false;
                    this.showQProductForm = false;
                    this.showCServiceForm = false;
                    this.showComplimentsForm = false;
                    this.showQServiceForm = false;

                    if (this.salesforceData && this.salesforceData.length > 0) {
                        const uniqueDescriptions = new Set();
                        this.filteredDescriptions = this.salesforceData
                            .filter(item => item.request_form === desc)
                            .filter(item => {
                                if (uniqueDescriptions.has(item.description_form)) {
                                    return false;
                                } else {
                                    uniqueDescriptions.add(item.description_form);
                                    return true;
                                }
                            })
                            .map(item => ({
                                id: item.id,
                                description_form: item.description_form,
                                reason_c: item.reason_c,
                                request_c: item.request_c,
                                country: item.country
                            }));
                    }

                    this.showCProductForm = true;
                }else if(desc === 'Claim - Service' || desc === 'Reclamo - Servicio'){
                    this.data = {
                        ...this.defaultValues(),
                        requestForm: desc,
                        requestTypeDescForm: null,
                    };
                    
                    this.filteredDescriptions = [];
                    this.showCServiceForm = false;
                    this.showQProductForm = false;
                    this.showCProductForm = false;
                    this.showComplimentsForm = false;
                    this.showQServiceForm = false;

                    if (this.salesforceData && this.salesforceData.length > 0) {
                        const uniqueDescriptions = new Set();
                        this.filteredDescriptions = this.salesforceData
                            .filter(item => item.request_form === desc)
                            .filter(item => {
                                if (uniqueDescriptions.has(item.description_form)) {
                                    return false;
                                } else {
                                    uniqueDescriptions.add(item.description_form);
                                    return true;
                                }
                            })
                            .map(item => ({
                                id: item.id,
                                description_form: item.description_form,
                                reason_c: item.reason_c,
                                request_c: item.request_c,
                                country: item.country
                            }));
                    }

                    this.showCServiceForm = true;
                }else if(desc === 'Query - Product' || desc === 'Consulta - Producto'){
                    this.data = {
                        ...this.defaultValues(),
                        requestForm: desc,
                        requestTypeDescForm: null,
                    };
                    
                    this.filteredDescriptions = [];
                    this.showQProductForm = false;
                    this.showCProductForm = false;
                    this.showCServiceForm = false;
                    this.showComplimentsForm = false;
                    this.showQServiceForm = false;

                    if (this.salesforceData && this.salesforceData.length > 0) {
                        const uniqueDescriptions = new Set();
                        this.filteredDescriptions = this.salesforceData
                            .filter(item => item.request_form === desc)
                            .filter(item => {
                                if (uniqueDescriptions.has(item.description_form)) {
                                    return false;
                                } else {
                                    uniqueDescriptions.add(item.description_form);
                                    return true;
                                }
                            })
                            .map(item => ({
                                id: item.id,
                                description_form: item.description_form,
                                reason_c: item.reason_c,
                                request_c: item.request_c,
                                country: item.country
                            }));
                    }

                    this.showQProductForm = true;
                }else if(desc === 'Query - Service' || desc === 'Consulta - Servicio'){
                    this.data = {
                        ...this.defaultValues(),
                        requestForm: desc,
                        requestTypeDescForm: null,
                    };
                    
                    this.filteredDescriptions = [];
                    this.showCServiceForm = false;
                    this.showQProductForm = false;
                    this.showCProductForm = false;
                    this.showQServiceForm = false;
                    this.showComplimentsForm = false;

                    if (this.salesforceData && this.salesforceData.length > 0) {
                        const uniqueDescriptions = new Set();
                        this.filteredDescriptions = this.salesforceData
                            .filter(item => item.request_form === desc)
                            .filter(item => {
                                if (uniqueDescriptions.has(item.description_form)) {
                                    return false;
                                } else {
                                    uniqueDescriptions.add(item.description_form);
                                    return true;
                                }
                            })
                            .map(item => ({
                                id: item.id,
                                description_form: item.description_form,
                                reason_c: item.reason_c,
                                request_c: item.request_c,
                                country: item.country
                            }));
                    }

                    this.showQServiceForm = true;
                }else if(desc === 'Compliments' || desc === 'Felicitaciones'){
                    this.data = {
                        ...this.defaultValues(),
                        requestForm: desc,
                        requestTypeDescForm: null,
                    };
                    
                    this.filteredDescriptions = [];
                    this.showCServiceForm = false;
                    this.showQProductForm = false;
                    this.showCProductForm = false;
                    this.showQServiceForm = false;
                    this.showComplimentsForm = false;

                    if (this.salesforceData && this.salesforceData.length > 0) {
                        const uniqueDescriptions = new Set();
                        this.filteredDescriptions = this.salesforceData
                            .filter(item => item.request_form === desc)
                            .filter(item => {
                                if (uniqueDescriptions.has(item.description_form)) {
                                    return false;
                                } else {
                                    uniqueDescriptions.add(item.description_form);
                                    return true;
                                }
                            })
                            .map(item => ({
                                id: item.id,
                                description_form: item.description_form,
                                reason_c: item.reason_c,
                                request_c: item.request_c,
                                country: item.country
                            }));
                    }

                    this.showComplimentsForm = true;
                }
                 else {
                    this.showCProductForm = false;
                    this.showCServiceForm = false;
                    this.showQProductForm = false;
                    this.showQServiceForm = false;
                    this.showComplimentsForm = false;
                }
            },
            getRequestByIdNew(id) {
                if(this.country === 'Colombia' || this.country === 'Argentina' || this.country === 'Calatin' || this.country === 'Mexico' || this.country === 'Uruguay'){
                    return this.salesforceData.find((request) => request.description_form == id);
                }else{
                    return this.requests.find((request) => request.name == id);
                }
            },

            loadFormCProduct() {
                const request = this.getRequestByIdNew(event.target.value);
                this.data = {...this.data, ...this.defaultValues()};
                this.products = [];
                this.data.account_name = this.user?.name;
                this.data.request_form = request.request_form;
                this.data.description_form = request.description_form;
                this.data.record_type_id = request.record_type_id;
                this.data.owner_id = request.owner_id;
                this.data.reason_c = request.reason_c;
                this.data.request_c = request.request_c;
                this.customManageFields();
            },
            loadFormCService() {
                const request = this.getRequestByIdNew(event.target.value);
                this.data = {...this.data, ...this.defaultValues()};
                this.products = [];
                this.data.account_name = this.user?.name;
                this.data.request_form = request.request_form;
                this.data.description_form = request.description_form;
                this.data.record_type_id = request.record_type_id;
                this.data.owner_id = request.owner_id;
                this.data.reason_c = request.reason_c;
                this.data.request_c = request.request_c;
                this.customManageFields();
            },
            loadFormQProduct() {
                const request = this.getRequestByIdNew(event.target.value);
                this.data = {...this.data, ...this.defaultValues()};
                this.products = [];
                this.data.account_name = this.user?.name;
                this.data.request_form = request.request_form;
                this.data.description_form = request.description_form;
                this.data.record_type_id = request.record_type_id;
                this.data.owner_id = request.owner_id;
                this.data.reason_c = request.reason_c;
                this.data.request_c = request.request_c;
                this.customManageFields();
            },
            loadFormQService() {
                const request = this.getRequestByIdNew(event.target.value);
                this.data = {...this.data, ...this.defaultValues()};
                this.products = [];
                this.data.account_name = this.user?.name;
                this.data.request_form = request.request_form;
                this.data.description_form = request.description_form;
                this.data.record_type_id = request.record_type_id;
                this.data.owner_id = request.owner_id;
                this.data.reason_c = request.reason_c;
                this.data.request_c = request.request_c;
                this.customManageFields();
            },
            loadFormCompliments() {
                const request = this.getRequestByIdNew(event.target.value);
                this.data = {...this.data, ...this.defaultValues()};
                this.products = [];
                this.data.account_name = this.user?.name;
                this.data.request_form = request.request_form;
                this.data.description_form = request.description_form;
                this.data.record_type_id = request.record_type_id;
                this.data.owner_id = request.owner_id;
                this.data.reason_c = request.reason_c;
                this.data.request_c = request.request_c;
                this.customManageFields();
            },
            customManageFields(){
                if(this.data.description_form === 'Product Defect claim' || this.data.description_form === 'Fuera de especificacion (Taste / Defects / Smell / Color / Textura)' 
                || this.data.description_form === 'Microbiological claim' || this.data.description_form === 'Microbiologia'
                || this.data.description_form === 'Foreign Material claim' || this.data.description_form === 'Elemento extrano' 
                || this.data.description_form === 'Cooking claim' || this.data.description_form === 'Problema en la coccion' 
                || this.data.description_form === 'Product query' || this.data.description_form === 'Informacion del producto'){
                    this.data.isCaseCostObject = false;
                }else{
                    this.sapBatchField = false;
                    this.beforeDateField = false;
                    this.lotNumberField = false;
                    this.data.isCaseCostObject = true;
                }
                if(this.data.description_form === 'Document query' || this.data.description_form === 'Consulta de documentacion' || this.data.description_form === 'Documentation query' 
                    || this.data.description_form === 'Packaging claim' || this.data.description_form === 'Packaging claims' 
                    || this.data.description_form === 'Problemas de empaque' || this.data.description_form === 'Reclamo por el empaque' ){
                    this.data.isCaseCostObject = true;
                }else{
                    this.data.isCaseCostObject = false;
                }
            },
            handleSelect(selectedOption) {
                if(this.data.description_form === 'Product Defect claim' || this.data.description_form === 'Fuera de especificacion (Taste / Defects / Smell / Color / Textura)' 
                    || this.data.description_form === 'Microbiological claim' || this.data.description_form === 'Microbiologia'
                    || this.data.description_form === 'Foreign Material claim' || this.data.description_form === 'Elemento extrano' 
                    || this.data.description_form === 'Cooking claim' || this.data.description_form === 'Problema en la coccion' 
                    || this.data.description_form === 'Product query' || this.data.description_form === 'Informacion del producto'){
                    if (this.data.productsSelected && Array.isArray(this.data.productsSelected)) {
                        this.data.productsSelected = [selectedOption];
                    } else {
                        this.data.productsSelected = selectedOption;
                    }
                    if(!this.data.isCaseCostObject && this.data.productsSelected.length > 0){
                        this.sapBatchField = true;
                        this.beforeDateField = true;
                        this.lotNumberField = true;
                    }
                }
            },
            handleRemove(removed) {
                console.log(removed)
                this.sapBatchField = false;
                this.beforeDateField = false;
                this.lotNumberField = false;
                this.data.lot_number = '';
                this.data.sap_batch = '';
                this.data.before_date = '';
            },
            getFormFieldsByRequestId(id) {
                const data = [
                    {
                        field: 'po_number',
                        required: [6],
                        ids: [2, 3, 4, 5, 6, 7]
                    },
                    {
                        field: 'product_number',
                        required: [5,6],
                        ids: [2, 3, 4, 5, 6, 7]
                    },
                    {
                        field: 'product_number_table',
                        required: [6],
                        ids: [6]
                    },
                    {
                        field: 'invoice_number',
                        required: [6],
                        ids: [2, 3, 4, 5, 6, 7]
                    },
                    {
                        field: 'lot_number',
                        required: [5],
                        ids: [5]
                    },
                    {
                        field: 'plant_name',
                        required: [4,5,6],
                        ids: [4,5,6]
                    },
                    {
                        field: 'description',
                        required: [2, 3, 4, 5, 6, 7],
                        ids: [2, 3, 4, 5, 6, 7]
                    }
                ];
                let dataForm = {};
                data.forEach((field) => {
                    if (field.required.includes(Number(id))) {
                        dataForm[field.field] = {
                            required: true
                        };
                    } else if (field.ids.includes(Number(id))) {
                        dataForm[field.field] = {
                            required: false
                        }
                    }
                });
                return dataForm;
            },
            defaultForm() {
                return {
                    product_number: {
                        required: false
                    },
                    product_number_table: {
                        required: false
                    },
                    lot_number: {
                        required: false
                    },
                    invoice_number: {
                        required: false
                    },
                    plant_name: {
                        required: false
                    },
                    po_number: {
                        required: false
                    }
                }
            },
            defaultValues() {
                return {
                    type_of_request: '',
                    contact_name: '',
                    contact_email: '',
                    account_name: '',
                    product_number: '',
                    product_number_table: '',
                    comments: '',
                    lot_number: '',
                    sap_batch: '',
                    before_date: '',
                    invoice_number: '', 
                    plant_name: '',
                    po_number: '',
                    plant_number: '',
                    productsSelected: [],
                }
            },
            cancel() {
                this.showForm = false;
                this.data = this.defaultValues();
            },
            async getOrdersByPONumberOldVersion(query = '') {
                if (query.length < 4 && query.length != 0) {
                    return;
                }
                const result = this.orders.find((order) => order.purchase_order.toLowerCase().includes(query.toLowerCase()));
                if (result) {
                    return;
                }
                this.isLoading = true
              axiosInstance
                  .get(`${this.$woe_api_uri}/order?page=1&size=10&purchaseOrder=${query}`)
                  .then((response) => {
                    this.orders = response.data.data.orders
                    this.isLoading = false
                  })
                  .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST") {
                      location.href = "/login";
                    } else {
                      this.showAlert('warning', `${this.$t("caution")}!`, error.response.data.error.message);
                    }
                  });
            },
            async getOrdersByPONumber(query = '') {
                if (query.length < 4 && query.length != 0) {
                    return;
                }
                
                const result = this.orders.find((order) => 
                    order.purchase_order.toLowerCase().includes(query.toLowerCase()) ||
                    order.order_number.toLowerCase().includes(query.toLowerCase())
                );
                
                if (result) {
                    return;
                }
                
                this.isLoading = true;
                try {
                    const response = await axiosInstance.get(`${this.$woe_api_uri}/order?page=1&size=10&purchaseOrder=${query}`);
                    this.orders = response.data.data.orders.map(order => ({
                        ...order,
                        label: `${order.purchase_order} - ${order.order_number}`
                    }));
                    this.isLoading = false;
                } catch (error) {
                    if (error.code === "ERR_BAD_REQUEST") {
                        location.href = "/login";
                    } else {
                        this.showAlert('warning', `${this.$t("caution")}!`, error.response.data.error.message);
                    }
                }
            },
            getOrderProductsByOrder(order) {
                axiosInstance.post(this.$woe_api_uri + '/order/items', 
                    {
                        'id': order.id
                    }
                ).then((response) => {
                    const products = response.data.data.items 
                    .map((product) => {
                        return {
                            ...product,
                            ...{ 
                                lot_number: '',
                                cases: 0, 
                                pricePerCase: 0,
                            }
                        }
                    });

                    this.products = products;
                })
                .catch((error) => {
                    this.showAlert('warning',`${this.$t("caution")}!`, error.response.data.error.message);
                });
            },
            onSelectPoNumber(order) {
                this.products = [];
                this.data.product_number_table = '';
                this.data.productsSelected = [];
                this.getOrderProductsByOrder(order);
                this.data.plant_name = `${order.plant_id} - ${order.plant_name}`;
                this.data.plant_number = order.plant_id;
            },
            reload() {
                this.cancel();
                window.location.reload();
            },

            validateEmail() {
                const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
                this.isEmailValid = emailRegex.test(this.data.contact_email);
            },
        }
    }
</script>
<style>

#form-claim-service{
    margin-top: -50px;
} 

#form-query-product{
    margin-top: -50px;
}

#form-query-service{
    margin-top: -50px;
}

#form-compliments{
    margin-top: -50px;
}

.table th{
    width: auto;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.pedido-steps form select, .pedido-steps form input, .pedido-steps form textarea {
	width: auto;
}

@media screen and (max-width: 768px){

    .form-container{
        padding: 20px;
        width: 100%;
    }

    .pedido-steps .section-title {
        padding: 40px 20px;
    }

    .pedido-steps .form-group select, label, option{
        width: 100%;
    }

    .btn-white {
        padding: 10px 15px;
        font-size: 14px;
        margin: 0 6px;
        min-width: auto;
    }

    .btn-green {
        min-width: auto;
    }
    
    .multiselect--above{
        width: 70%;
    }

    .multiselect{
        width: 70%;
    }

    .table {
        width: 100%;
        overflow-x: auto;
        display: block;
    }

    .table thead {
        display: block;
        width: 100%;
    }

    .table tbody {
        display: block;
        width: 100%;
    }

    .table tr {
        justify-content: space-between;
        gap: 200px;
    }

    thead tr{
        display: flex;
    }

    tbody tr{
        display: flex;
    }

    .pedido-steps table tbody{
        display: block;
    }

    .table th,
    .table td {
        flex: 1 1 auto;
        min-width: 100px;
        box-sizing: border-box;
        text-align: left;
        white-space: nowrap;
        padding: 8px;
    }

    .table thead th {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 1;
    }

    .pedido-steps table th, .pedido-list table th{
        padding: 8px 0px;
    }

    .pedido-steps table td, .pedido-list table td{
        padding: 8px 0px;
    }

    .pedido-steps form select, .pedido-steps form input, .pedido-steps form textarea{
        padding: 0px 0px 0px 5px;
    }
    
    .multiselect-label{
        width: 70%;
    }


}




</style>